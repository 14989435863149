form {
	fieldset {
		border: none;
		padding: 0;
		margin: 0;
	}
}

.input__wrapper {
	margin-bottom: 20px;

	&.input--hidden {
		display: none;
	}

	label {
		display: block;
		text-transform: uppercase;
		color: $green;
		letter-spacing: 2px;
		margin-bottom: 15px;
	}

	&.error input {
		border-color: $red;
		color: $red;
	}

	input {
		border-radius: 5px;
		border: 1px solid $black;
		padding: 15px;

		font-size: 14px;
		width: 100%;

		&.error {
			border: 1px solid $red;
		}
	}

	.input__description {
		font-size: 12px;
		line-height: 18px;
		margin-top: 5px;
	}

	.input__sideinfo {
		display: flex;
		align-items: center;

		@include below($mobile) {
			flex-wrap: wrap;
		}

		> p {
			font-size: 14px;
			font-weight: 600;

			@include above($mobile) {
				order: 2;
				padding-left: 20px;
			}

			a {
				color: $light-blue;
			}
		}

		.input__btn--wrapper {
			@include above($mobile) {
				order: 1;
			}
		}
	}

	select {
		appearance: none;
		webkit-appearance: none;

		border-radius: 5px;
		border: 1px solid $black;
		padding: 15px;

		font-size: 14px;
		width: 100%;

		background-color: transparent;
		background-image: url($img-path + 'arrow-down.svg');
		background-repeat: no-repeat;
		background-position: right 10px center;

		cursor: pointer;

		&.error {
			border: 1px solid $red;
		}
	}
}




@mixin visually-hidden() {
	position: absolute !important;
	overflow: hidden;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
	clip: rect(0 0 0 0);
  }
  
  
  .checkbox {
  
	position: relative;
	margin-bottom: 20px;

	a {
		margin-left: 8px;
	}

	&.error label {
		color: $red;

		a {
			color: $red;
		}
	}

	&__input {
	  @include visually-hidden();
	}
  
	&__label {
	  cursor: pointer;
	  display: flex;
	  align-items: center;
	}
  
	&__label::before {
	  content: "";
	  width: 20px;
	  height: 20px;
	  border: 1px solid $light-green;
	  border-radius: 3px;
	  margin-right: 10px;
	}
  
	&__input:checked + &__label::after {
	  content: "";
	  width: 14px;
	  height: 14px;
	  background: $light-green;
	  border-radius: 3px;
	  position: absolute;
	  left: 3px;
	  top: 5px;
	}
  }

  .voucher--error {
	  padding: 15px;
	  color: $red;
	  display: none;

	  &.show {
		  display: block;
	  }
  }