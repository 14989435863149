.page__404 {

	.p-404__wrapper {
		align-items: center;
		justify-content: center;

		min-height: 500px;

		@include below($tablet) {
			min-height: 350px;
		}

		.p-404__inner {
			h4 {
				text-align: center;
				margin: 20px 0;
			}
			h1 {
				text-align: center;
			}
			p {
				color: $mid-gray;
				max-width: 440px;
				width: 100%;
				padding: 0 20px;
				margin: auto;

				text-align: center;
				line-height: 1.7em;
				@include below($mobile) {
					width: 85%;
					padding: 0;
				}
				a {
					color: $green;
					text-decoration: none;
					text-transform: uppercase;
				}
			}
		}
	}
}
