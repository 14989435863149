.question__mark {
	position: relative;

	.tooltip__box {
		top: -5px;
		right: 105%;
		margin-left: 15px;
	}

	&:hover {
		.tooltip__box {
			visibility: visible;
			opacity: 1;
		}
	}

	&--inner {
		color: $green;
		background-color: $white;
		width: 16px;
		height: 16px;
		line-height: rem(18px);

		border-radius: 50%;
		text-align: center;
		vertical-align: middle;
		display: inline-block;
	}
}
