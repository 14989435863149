.hero {
	background-image: url($img-path + 'hero__bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	min-height: 350px;

	@include below($mobile) {
		min-height: 280px;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: center;

		height: 100%;
		min-height: 350px;
		text-align: center;
		padding: 50px 0;

		@include below($mobile) {
			min-height: 280px;
		}
	}

	p {
		color: $white;
		font-family: $title-font-family;
	}

	&__description {
		max-width: 550px;
		margin: 50px auto 0 auto;
	}

	.btn__wrapper {
		margin-top: 50px;
	}

}
