.secondary__nav {
	border: 1px solid $green;
	border-top: none;

	.col--3 {
		padding: 10px;
	}

	&-item {
		text-align: center;
		text-transform: uppercase;
		color: $green;

		&.locked {
			color: $mid-gray;
		}
	}
}
