@charset "UTF-8";
/* Slider */
/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.selectric-wrapper {
  position: relative;
  cursor: pointer; }

.selectric-responsive {
  width: 100%; }

.selectric {
  border: 1px solid #DDD;
  border-radius: 0px;
  background: #F8F8F8;
  position: relative;
  overflow: hidden; }
  .selectric .label {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 38px 0 10px;
    font-size: 12px;
    line-height: 38px;
    color: #444;
    height: 38px;
    user-select: none; }
  .selectric .button {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    line-height: 38px;
    background-color: #F8f8f8;
    color: #BBB;
    text-align: center;
    font: 0/0 a;
    *font: 20px/38px Lucida Sans Unicode, Arial Unicode MS, Arial; }
    .selectric .button:after {
      content: " ";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 0;
      height: 0;
      border: 4px solid transparent;
      border-top-color: #BBB;
      border-bottom: none; }

.selectric-focus .selectric {
  border-color: #aaaaaa; }

.selectric-hover .selectric {
  border-color: #c4c4c4; }
  .selectric-hover .selectric .button {
    color: #a2a2a2; }
    .selectric-hover .selectric .button:after {
      border-top-color: #a2a2a2; }

.selectric-open {
  z-index: 9999; }
  .selectric-open .selectric {
    border-color: #c4c4c4; }
  .selectric-open .selectric-items {
    display: block; }

.selectric-disabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  user-select: none; }

.selectric-hide-select {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0; }
  .selectric-hide-select select {
    position: absolute;
    left: -100%; }
  .selectric-hide-select.selectric-is-native {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10; }
    .selectric-hide-select.selectric-is-native select {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border: none;
      z-index: 1;
      box-sizing: border-box;
      opacity: 0; }

.selectric-input {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important; }

.selectric-temp-show {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important; }

/* Items box */
.selectric-items {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F8F8F8;
  border: 1px solid #c4c4c4;
  z-index: -1;
  box-shadow: 0 0 10px -6px; }
  .selectric-items .selectric-scroll {
    height: 100%;
    overflow: auto; }
  .selectric-above .selectric-items {
    top: auto;
    bottom: 100%; }
  .selectric-items ul, .selectric-items li {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    line-height: 20px;
    min-height: 20px; }
  .selectric-items li {
    display: block;
    padding: 10px;
    color: #666;
    cursor: pointer; }
    .selectric-items li.selected {
      background: #E0E0E0;
      color: #444; }
    .selectric-items li.highlighted {
      background: #D0D0D0;
      color: #444; }
    .selectric-items li:hover {
      background: #D5D5D5;
      color: #444; }
  .selectric-items .disabled {
    filter: alpha(opacity=50);
    opacity: 0.5;
    cursor: default !important;
    background: none !important;
    color: #666 !important;
    user-select: none; }
  .selectric-items .selectric-group .selectric-group-label {
    font-weight: bold;
    padding-left: 10px;
    cursor: default;
    user-select: none;
    background: none;
    color: #444; }
  .selectric-items .selectric-group.disabled li {
    filter: alpha(opacity=100);
    opacity: 1; }
  .selectric-items .selectric-group li {
    padding-left: 25px; }

@media screen and (max-width: 1440px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1439px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1280px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1279px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 1100px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 1099px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 990px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 989px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 768px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 767px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 600px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 599px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (max-width: 480px) {
  .foo {
    bar: 'below'; } }

@media screen and (max-width: 479px) {
  .foo {
    bar: 'below -1'; } }

@media screen and (min-width: 479px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 480px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 599px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 600px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 767px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 768px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 989px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 990px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1099px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1100px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1279px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1280px) {
  .foo {
    bar: 'above'; } }

@media screen and (min-width: 1439px) {
  .foo {
    bar: 'above -1'; } }

@media screen and (min-width: 1440px) {
  .foo {
    bar: 'above'; } }

@font-face {
  font-family: "Gotham";
  font-weight: 300;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/gotham/Gotham-Light.woff") format("woff"), url("../fonts/gotham/Gotham-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/Gotham-Light.ttf") format("truetype"), url("../fonts/gotham/Gotham-Light.svg#webfont") format("svg"); }

@font-face {
  font-family: "Gotham";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/gotham/Gotham-Medium.woff") format("woff"), url("../fonts/gotham/Gotham-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/Gotham-Medium.ttf") format("truetype"), url("../fonts/gotham/Gotham-Medium.svg#webfont") format("svg"); }

@font-face {
  font-family: "Gotham";
  font-weight: 600;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/gotham/Gotham-Bold.woff") format("woff"), url("../fonts/gotham/Gotham-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/Gotham-Bold.ttf") format("truetype"), url("../fonts/gotham/Gotham-Bold.svg#webfont") format("svg"); }

@font-face {
  font-family: "Gotham Book";
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: url("../fonts/gotham/Gotham-Book.woff") format("woff"), url("../fonts/gotham/Gotham-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/gotham/Gotham-Book.ttf") format("truetype"), url("../fonts/gotham/Gotham-Book.svg#webfont") format("svg"); }

html {
  box-sizing: border-box;
  font-size: 100%;
  line-height: 1.4; }

*,
*::before,
*::after {
  box-sizing: inherit; }

:root {
  -moz-tab-size: 4;
  tab-size: 4; }

body {
  margin: 0;
  font-family: "Gotham Book", sans-serif;
  font-size: 16px;
  line-height: 1.4;
  color: #323241;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

h1 {
  font-size: 2em;
  margin: 0.67em 0; }

hr {
  height: 0; }

abbr[title] {
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

code,
kbd,
samp,
pre {
  font-family: monospace;
  font-size: 1em; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.4;
  margin: 0; }

button,
select {
  text-transform: none; }

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button; }

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0; }

button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText; }

fieldset {
  padding: 0.35em 0.75em 0.625em; }

legend {
  padding: 0; }

progress {
  vertical-align: baseline; }

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto; }

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type='search']::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

summary {
  display: list-item; }

* {
  outline: none; }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1.25rem; }

h1,
.block__image--title {
  font-size: 2.125rem;
  line-height: 2.375rem;
  color: #FFFFFF;
  font-family: "Gotham", sans-serif;
  font-weight: 600;
  max-width: 900px;
  margin: 0 auto; }
  @media screen and (min-width: 600px) {
    h1,
    .block__image--title {
      font-size: 2.75rem;
      line-height: 3rem; } }
  @media screen and (min-width: 990px) {
    h1,
    .block__image--title {
      font-size: 4rem;
      line-height: 4.25rem; } }

h2,
.section__title {
  font-size: 26px;
  line-height: 38px;
  font-family: "Gotham", sans-serif;
  font-weight: 600; }
  @media screen and (min-width: 600px) {
    h2,
    .section__title {
      font-size: 2.25rem;
      line-height: 3rem; } }

a:not(.btn) {
  color: #457B20;
  text-decoration: underline;
  transition: all .2s ease-in-out; }
  a:not(.btn):hover {
    color: #4DA330; }

.section__spacing {
  padding: 30px 0; }
  @media screen and (min-width: 768px) {
    .section__spacing {
      padding: 60px 0; } }

main {
  margin: 50px 0; }
  @media screen and (max-width: 990px) {
    main {
      padding-top: 62px; } }

.col--form.hide,
.col--thankyou.hide {
  display: none; }

.flex__container {
  display: flex;
  flex-wrap: wrap; }
  .flex__container.flex__center {
    justify-content: center; }
  .flex__container.hide {
    display: none; }
  .flex__container.flex__align--center {
    align-items: center; }
  .flex__container.flex__spacing {
    justify-content: space-between; }

@media screen and (min-width: 990px) {
  .spacing__container {
    padding-left: calc( 100% / 12); } }

.col--3,
.col--3-2,
.col--4,
.col--6,
.col--12 {
  padding: 0.625rem; }
  @media screen and (min-width: 990px) {
    .col--3,
    .col--3-2,
    .col--4,
    .col--6,
    .col--12 {
      padding: 1.25rem; } }

.col--1 {
  width: calc( 100% / 12); }

.col--3 {
  width: 100%; }
  @media screen and (min-width: 600px) {
    .col--3 {
      width: 50%; } }
  @media screen and (min-width: 768px) {
    .col--3 {
      width: 33.33%; } }

.col--4 {
  width: 100%; }
  @media screen and (min-width: 600px) {
    .col--4 {
      width: 50%; } }
  @media screen and (min-width: 768px) {
    .col--4 {
      width: 25%; } }

.col--3-2 {
  width: 100%; }
  @media screen and (min-width: 600px) {
    .col--3-2 {
      width: 50%; } }
  @media screen and (min-width: 768px) {
    .col--3-2 {
      width: 66.66%; } }

.col--6 {
  width: 100%; }
  @media screen and (min-width: 768px) {
    .col--6 {
      width: 50%; } }

.col--12 {
  width: 100%; }

.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 350px; }
  @media screen and (min-width: 600px) {
    .banner {
      min-height: 440px; } }
  .banner__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 350px;
    text-align: center;
    padding: 50px 0; }
    @media screen and (min-width: 600px) {
      .banner__inner {
        min-height: 440px; } }
  .banner p {
    color: #FFFFFF;
    font-family: "Gotham", sans-serif; }
  .banner__description {
    max-width: 550px;
    margin: 50px auto 0 auto; }
  .banner .btn__wrapper {
    margin-top: 50px; }

.btn {
  min-width: 180px;
  padding: 10px 15px;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(187, 187, 187, 0.5);
  font-family: "Gotham", sans-serif;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  transition: all .2s ease-in-out;
  cursor: pointer; }
  .btn--red {
    background-color: #DD042B;
    color: #FFFFFF; }
    .btn--red:hover {
      background-color: #F19991; }
  .btn--white {
    background-color: #FFFFFF;
    color: #00A7BA; }
    .btn--white:hover {
      color: #86CEDC; }

.footer {
  background-color: #FEFBF7;
  padding: 20px 0; }
  .footer__privacy {
    font-size: 0.875rem;
    line-height: 1.5625rem; }
  .footer__logo {
    background-image: url("../img/logo.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 90px;
    height: 130px; }
    @media screen and (min-width: 768px) {
      .footer__logo {
        margin-bottom: 0; } }
  .footer__item {
    padding: 5px 20px; }
    @media screen and (min-width: 768px) {
      .footer__item {
        padding: 0 20px; } }
    @media screen and (min-width: 768px) {
      .footer__item:nth-child(2) {
        margin-right: auto; } }
    @media screen and (min-width: 768px) {
      .footer__item:last-child {
        padding-right: 0; } }
    .footer__item a {
      font-family: "Gotham", sans-serif;
      text-transform: uppercase;
      text-decoration: none; }
  .footer .flex__container {
    flex-direction: column; }
    @media screen and (min-width: 768px) {
      .footer .flex__container {
        flex-direction: row; } }
  .footer__inner .container > .col--12 {
    padding-top: 0;
    padding-bottom: 0; }

form fieldset {
  border: none;
  padding: 0;
  margin: 0; }

.input__wrapper {
  margin-bottom: 20px; }
  .input__wrapper.input--hidden {
    display: none; }
  .input__wrapper label {
    display: block;
    text-transform: uppercase;
    color: #457B20;
    letter-spacing: 2px;
    margin-bottom: 15px; }
  .input__wrapper.error input {
    border-color: #DD042B;
    color: #DD042B; }
  .input__wrapper input {
    border-radius: 5px;
    border: 1px solid #323241;
    padding: 15px;
    font-size: 14px;
    width: 100%; }
    .input__wrapper input.error {
      border: 1px solid #DD042B; }
  .input__wrapper .input__description {
    font-size: 12px;
    line-height: 18px;
    margin-top: 5px; }
  .input__wrapper .input__sideinfo {
    display: flex;
    align-items: center; }
    @media screen and (max-width: 480px) {
      .input__wrapper .input__sideinfo {
        flex-wrap: wrap; } }
    .input__wrapper .input__sideinfo > p {
      font-size: 14px;
      font-weight: 600; }
      @media screen and (min-width: 480px) {
        .input__wrapper .input__sideinfo > p {
          order: 2;
          padding-left: 20px; } }
      .input__wrapper .input__sideinfo > p a {
        color: #86CEDC; }
    @media screen and (min-width: 480px) {
      .input__wrapper .input__sideinfo .input__btn--wrapper {
        order: 1; } }
  .input__wrapper select {
    appearance: none;
    webkit-appearance: none;
    border-radius: 5px;
    border: 1px solid #323241;
    padding: 15px;
    font-size: 14px;
    width: 100%;
    background-color: transparent;
    background-image: url("../img/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer; }
    .input__wrapper select.error {
      border: 1px solid #DD042B; }

.checkbox {
  position: relative;
  margin-bottom: 20px; }
  .checkbox a {
    margin-left: 8px; }
  .checkbox.error label {
    color: #DD042B; }
    .checkbox.error label a {
      color: #DD042B; }
  .checkbox__input {
    position: absolute !important;
    overflow: hidden;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0); }
  .checkbox__label {
    cursor: pointer;
    display: flex;
    align-items: center; }
  .checkbox__label::before {
    content: "";
    width: 20px;
    height: 20px;
    border: 1px solid #4DA330;
    border-radius: 3px;
    margin-right: 10px; }
  .checkbox__input:checked + .checkbox__label::after {
    content: "";
    width: 14px;
    height: 14px;
    background: #4DA330;
    border-radius: 3px;
    position: absolute;
    left: 3px;
    top: 5px; }

.voucher--error {
  padding: 15px;
  color: #DD042B;
  display: none; }
  .voucher--error.show {
    display: block; }

.js-fixed-header .header__logo:after {
  content: none; }

@media screen and (min-width: 990px) {
  .js-fixed-header .header__logo img {
    width: 4.375rem;
    height: auto; } }

.js-fixed-header .header .navigation__tel {
  display: none; }

.header {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10; }
  @media screen and (max-width: 990px) {
    .header {
      background-color: #FFFFFF;
      box-shadow: 0 2px 4px 0 rgba(193, 193, 193, 0.5); } }
  @media screen and (min-width: 990px) {
    .header {
      height: auto;
      position: absolute; }
      .header.fixed {
        position: fixed;
        background-color: #FFFFFF;
        height: 90px;
        box-shadow: 0 2px 4px 0 rgba(193, 193, 193, 0.5); }
        .header.fixed .header__inner {
          align-items: center; }
        .header.fixed .header__nav ul li a {
          color: #457B20; }
        .header.fixed .navigation__language--item:after {
          background-color: #457B20; }
        .header.fixed .navigation__list-item a:after {
          background-color: #457B20; } }
  .header__logo {
    position: relative; }
    @media screen and (min-width: 990px) {
      .header__logo:after {
        content: "";
        background-color: #FFFFFF;
        position: absolute;
        top: 0;
        left: -8px;
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px; } }
    .header__logo img {
      width: 100px;
      height: auto;
      position: relative;
      z-index: 12;
      margin-top: -6px;
      padding-bottom: 6px; }
      @media screen and (max-width: 990px) {
        .header__logo img {
          width: 50px;
          margin-top: 0;
          padding-bottom: 0; } }
  .header__nav {
    margin-top: 10px;
    margin-left: auto; }
    @media screen and (max-width: 990px) {
      .header__nav {
        vertical-align: middle;
        background-color: #FFFFFF;
        position: fixed;
        z-index: 10;
        top: 0;
        left: -80%;
        height: 100%;
        min-height: 100%;
        margin-top: 0;
        padding-top: 80px;
        width: 80%;
        transition: all 250ms ease-in-out;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        box-shadow: 0 2px 4px 0 rgba(193, 193, 193, 0.5); }
        .header__nav ul li a {
          color: #457B20; }
        .header__nav .navigation__list {
          padding: 0 6px; }
        .header__nav .navigation__list-item {
          display: block;
          padding: 10px 15px;
          position: relative; }
          .header__nav .navigation__list-item.is-active a {
            font-weight: 600; }
          .header__nav .navigation__list-item:after {
            content: "";
            position: absolute;
            width: calc( 100% - 30px);
            height: 1px;
            left: 15px;
            bottom: 0;
            background-color: #DDDDDD; }
        .header__nav .navigation {
          display: block; }
        .header__nav .navigation__language-wrapper {
          padding: 0 22px; }
        .header__nav .navigation__language--item:after {
          background-color: #457B20; } }
    .header__nav.open {
      display: block;
      left: 0; }
      @media screen and (min-width: 990px) {
        .header__nav.open {
          display: inline-block; } }
    .header__nav .navigation,
    .header__nav .navigation__language {
      display: inline-block; }
    .header__nav ul li {
      list-style: none;
      display: inline-block;
      padding: 0 15px; }
      .header__nav ul li a {
        color: #FFFFFF;
        font-family: "Gotham", sans-serif;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase; }
        .header__nav ul li a:hover {
          color: #FFFFFF; }
    .header__nav .navigation__list-item a {
      display: inline;
      position: relative;
      overflow: hidden; }
      .header__nav .navigation__list-item a:after {
        content: "";
        position: absolute;
        z-index: -1;
        right: 0;
        width: 0;
        bottom: -5px;
        background: #FFFFFF;
        height: 1px;
        transition-property: width;
        transition-duration: 0.3s;
        transition-timing-function: ease-out; }
      .header__nav .navigation__list-item a:hover:after, .header__nav .navigation__list-item a:focus:after, .header__nav .navigation__list-item a:active:after {
        left: 0;
        right: auto;
        width: 100%; }
    .header__nav .navigation__list-item.is-active a:after {
      left: 0;
      right: auto;
      width: 100%; }
    .header__nav .navigation__language ul li {
      padding: 0; }
      .header__nav .navigation__language ul li a {
        font-weight: 300;
        letter-spacing: 0; }
    .header__nav .navigation__language--item {
      position: relative;
      padding-right: 10px !important;
      font-size: 14px; }
      .header__nav .navigation__language--item:after {
        content: "";
        position: absolute;
        width: 1px;
        height: 60%;
        background-color: #FFFFFF;
        top: 5px;
        right: 2px; }
      .header__nav .navigation__language--item:last-child:after {
        content: none; }
      .header__nav .navigation__language--item a.active {
        font-weight: 400; }

.header__hamburger {
  width: 2.5rem;
  height: 2.5rem;
  position: relative;
  z-index: 10;
  transform: scale(0.8);
  transition: all 0.5s ease-in-out;
  float: right;
  vertical-align: middle;
  margin-top: 18px;
  margin-left: auto;
  cursor: pointer; }
  @media screen and (min-width: 990px) {
    .header__hamburger {
      display: none; } }
  .header__hamburger span {
    display: block;
    position: absolute;
    height: 0.25rem;
    width: 100%;
    background-color: #457B20;
    border-radius: 0.625rem;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: all 0.25s ease-in-out; }

.header__bar:nth-child(1) {
  top: 0; }

.header__bar:nth-child(2), .header__bar:nth-child(3) {
  top: 0.75rem; }

.header__bar:nth-child(4) {
  top: 1.5rem; }

.header__hamburger.open .header__bar:nth-child(1), .header__hamburger.open .header__bar:nth-child(4) {
  top: 1.125rem;
  width: 0;
  left: 50%; }

.header__hamburger.open .header__bar:nth-child(2) {
  transform: rotate(45deg); }

.header__hamburger.open .header__bar:nth-child(3) {
  transform: rotate(-45deg); }

.header__hamburger.open .header__bar:nth-child(4) {
  top: 0.625rem;
  width: 0;
  left: 50%; }

.hero {
  background-image: url("../img/hero__bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 350px; }
  @media screen and (max-width: 480px) {
    .hero {
      min-height: 280px; } }
  .hero__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 350px;
    text-align: center;
    padding: 50px 0; }
    @media screen and (max-width: 480px) {
      .hero__inner {
        min-height: 280px; } }
  .hero p {
    color: #FFFFFF;
    font-family: "Gotham", sans-serif; }
  .hero__description {
    max-width: 550px;
    margin: 50px auto 0 auto; }
  .hero .btn__wrapper {
    margin-top: 50px; }

.image__slider .image__slide {
  min-height: 350px;
  position: relative;
  opacity: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  transition: all .2s ease-in-out; }
  @media screen and (min-width: 768px) {
    .image__slider .image__slide {
      min-height: 500px; } }
  .image__slider .image__slide.has-link:hover {
    opacity: 0.6; }
  .image__slider .image__slide a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

.image__slider.slick-dotted.slick-slider {
  margin-bottom: 0; }

.image__slider .slick-dots {
  bottom: 35px;
  width: calc( 100% - 40px); }
  .image__slider .slick-dots li {
    margin: 0; }
    .image__slider .slick-dots li button:before {
      font-size: 0.625rem;
      color: #FFFFFF;
      opacity: 1; }
    .image__slider .slick-dots li.slick-active button:before {
      color: #4A4A4A; }

.image__slider .slick-list,
.image__slider .slick-track,
.image__slider .slick-slide > div,
.image__slider .image__slide {
  height: 100%; }

@media screen and (min-width: 768px) {
  .intro__block--inner {
    max-width: 280px; } }

.intro__block h3 {
  color: #457B20;
  letter-spacing: 1.5px;
  line-height: 22px;
  text-transform: uppercase;
  font-weight: 300; }

.intro__block--description {
  font-size: 0.875rem; }

.question__mark {
  position: relative; }
  .question__mark .tooltip__box {
    top: -5px;
    right: 105%;
    margin-left: 15px; }
  .question__mark:hover .tooltip__box {
    visibility: visible;
    opacity: 1; }
  .question__mark--inner {
    color: #457B20;
    background-color: #FFFFFF;
    width: 16px;
    height: 16px;
    line-height: 1.125rem;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    display: inline-block; }

.recipes__thumb-image {
  width: 100%;
  height: 240px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }

.recipes__thumb-title {
  padding: 0 15px;
  font-family: "Gotham", sans-serif;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px; }

.recipes__thumb-content {
  padding: 0 15px 15px 15px;
  font-size: 14px; }
  .recipes__thumb-content p {
    line-height: 18px; }

.recipes__thumb-list {
  padding: 0;
  margin: 0;
  text-transform: uppercase; }
  .recipes__thumb-list li {
    list-style: none; }
  .recipes__thumb-list.ingredients {
    text-transform: none; }
    .recipes__thumb-list.ingredients li {
      position: relative;
      padding-left: 15px; }
      .recipes__thumb-list.ingredients li:before {
        content: "-";
        position: absolute;
        left: 0; }

a.recipes__thumb-inner {
  background-color: #457B20;
  color: #FFFFFF;
  display: block;
  border-radius: 5px;
  padding: 5px;
  text-decoration: none; }
  a.recipes__thumb-inner:hover {
    color: #FFFFFF;
    background-color: #4DA330; }

.recipes__thumb-inner {
  background-color: #457B20;
  color: #FFFFFF;
  display: block;
  border-radius: 5px;
  padding: 5px;
  text-decoration: none; }

.secondary__nav {
  border: 1px solid #457B20;
  border-top: none; }
  .secondary__nav .col--3 {
    padding: 10px; }
  .secondary__nav-item {
    text-align: center;
    text-transform: uppercase;
    color: #457B20; }
    .secondary__nav-item.locked {
      color: #BBBBBB; }

.content__container figure {
  margin-left: 0;
  margin-right: 0; }
  .content__container figure img {
    max-width: 100%;
    height: auto; }

@media screen and (min-width: 600px) {
  .page__homepage .hero {
    min-height: 550px; } }

@media screen and (min-width: 600px) {
  .page__homepage .hero__inner {
    min-height: 550px; } }

.page__homepage .section__spacing:nth-child(3) {
  padding-top: 0; }

.page__recipe .recipes__thumb {
  padding: 0; }

.page__recipe ol {
  padding-left: 18px; }

.recipe__title {
  font-family: "Gotham", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px; }

.recipe__content {
  margin-bottom: 60px; }
  .recipe__content li {
    padding-bottom: 10px; }

.recipes__thumbs .recipes__thumb {
  display: none; }
  .recipes__thumbs .recipes__thumb > a {
    height: 100%; }

.tooltip__box {
  background-color: #FFFFFF;
  color: #457B20;
  visibility: hidden;
  opacity: 0;
  text-align: center;
  padding: 5px 0;
  border-radius: 3px;
  position: absolute;
  z-index: 1;
  width: 200px;
  top: 110%;
  left: 50%;
  margin-left: -100px;
  font-size: 0.875rem;
  text-transform: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.13);
  transition: all .2s ease-in-out; }

.unlocked {
  cursor: pointer;
  transition: all .2s ease-in-out; }
  .unlocked.active {
    font-weight: bold; }
  .unlocked:before {
    content: "";
    background-image: url("../img/unlocked.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 14px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .unlocked:hover {
    color: #4DA330; }
    .unlocked:hover:before {
      animation: unlocked 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px; }

.locked {
  position: relative; }
  .locked:before {
    content: "";
    background-image: url("../img/locked.svg");
    background-repeat: no-repeat;
    background-size: contain;
    width: 14px;
    height: 22px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px; }
  .locked:hover:before {
    animation: locked 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px; }
  .locked:hover .tooltip__box {
    visibility: visible;
    opacity: 1; }

@keyframes unlocked {
  0% {
    transform: scale(1) rotate(0deg); }
  33% {
    transform: scale(1.2) rotate(5deg); }
  66% {
    transform: scale(1.2) rotate(-5deg); }
  100% {
    transform: scale(1) rotate(0deg); } }

@keyframes locked {
  0% {
    transform: rotate(0deg);
    transform-origin: center top; }
  10% {
    transform: rotate(1deg);
    transform-origin: center top; }
  20% {
    transform: rotate(-2deg);
    transform-origin: center top; }
  30% {
    transform: rotate(3deg);
    transform-origin: center top; }
  40% {
    transform: rotate(-4deg);
    transform-origin: center top; }
  50% {
    transform: rotate(4deg);
    transform-origin: center top; }
  60% {
    transform: rotate(-4deg);
    transform-origin: center top; }
  70% {
    transform: rotate(3deg);
    transform-origin: center top; }
  80% {
    transform: rotate(-2deg);
    transform-origin: center top; }
  90% {
    transform: rotate(1deg);
    transform-origin: center top; }
  100% {
    transform: rotate(0);
    transform-origin: center top; } }

.page__startpackage .section__title h2 {
  margin: 0; }

.page__startpackage p {
  margin: 0; }

.page__startpackage .section__spacing:last-child {
  padding-top: 0; }

.page__startpackage .js__startpacket .flex__container {
  justify-content: space-between;
  margin-bottom: 20px; }
  .page__startpackage .js__startpacket .flex__container div[class*="col--"] {
    padding: 0; }

.page__startpackage .js__startpacket .col--6 {
  width: calc(50% - 10px); }
  @media screen and (min-width: 768px) {
    .page__startpackage .js__startpacket .col--6 {
      width: calc(50% - 20px); } }

.page__thankyou p {
  margin-top: 0; }

.page__thankyou .btn__wrapper {
  margin-top: 30px; }

.page__404 .p-404__wrapper {
  align-items: center;
  justify-content: center;
  min-height: 500px; }
  @media screen and (max-width: 768px) {
    .page__404 .p-404__wrapper {
      min-height: 350px; } }
  .page__404 .p-404__wrapper .p-404__inner h4 {
    text-align: center;
    margin: 20px 0; }
  .page__404 .p-404__wrapper .p-404__inner h1 {
    text-align: center; }
  .page__404 .p-404__wrapper .p-404__inner p {
    color: #BBBBBB;
    max-width: 440px;
    width: 100%;
    padding: 0 20px;
    margin: auto;
    text-align: center;
    line-height: 1.7em; }
    @media screen and (max-width: 480px) {
      .page__404 .p-404__wrapper .p-404__inner p {
        width: 85%;
        padding: 0; } }
    .page__404 .p-404__wrapper .p-404__inner p a {
      color: #457B20;
      text-decoration: none;
      text-transform: uppercase; }
