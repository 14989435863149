.content__container {
	figure {
		margin-left: 0;
		margin-right: 0;

		img {
			max-width: 100%;
			height: auto;
		}
	}
}
