.btn {
	min-width: 180px;
	padding: 10px 15px;
	border-radius: 3px;
	box-shadow: 0 2px 4px 0 rgba(187,187,187,0.50);

	font-family: $title-font-family;
	text-align: center;
	text-decoration: none;
	display: inline-block;

	transition: all .2s ease-in-out;
	cursor: pointer;

	&--red {
		background-color: $red;
		color: $white;

		&:hover {
			background-color: $pink;
		}
	}

	&--white {
		background-color: $white;
		color: $blue;

		&:hover {
			color: $light-blue;
		}
	}
}
