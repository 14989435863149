.footer {
	background-color: $cream;
	padding: 20px 0;

	&__privacy {
		font-size: rem(14px);
		line-height: rem(25px);
	}

	&__logo {
		background-image: url($img-path + 'logo.svg');
		background-repeat: no-repeat;
		background-size: contain;

		width: 90px;
		height: 130px;

		@include above($tablet) {
			margin-bottom: 0;
		}
	}

	&__item {
		padding: 5px 20px;

		@include above($tablet) {
			padding: 0 20px;
		}

		&:nth-child(2) {
			@include above($tablet) {
				margin-right: auto;
			}
		}

		&:last-child {
			@include above($tablet) {
				padding-right: 0;
			}
		}

		a {
			font-family: $title-font-family;
			text-transform: uppercase;
			text-decoration: none;
		}
	}

	.flex__container {
		flex-direction: column;

		@include above($tablet) {
			flex-direction: row;
		}
	}

	&__inner {
		.container {
			> .col--12 {
				padding-top: 0;
				padding-bottom: 0;
			}
		}
	}
}
