.page__startpackage {
	.section__title {
		h2 {
			margin: 0;
		}
	}

	p {
		margin: 0;
	}

	.section__spacing {
		&:last-child {
			padding-top: 0;
		}
	}

	.js__startpacket {
		.flex__container {
			justify-content: space-between;
			margin-bottom: 20px;

			div[class*="col--"] {
				padding: 0;
			}
		}

		.col--6 {
			width: calc(50% - 10px);

			@include above($tablet) {
				width: calc(50% - 20px);
			}
		}
	}
}
