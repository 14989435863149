.page__homepage {
	.hero {
		@include above($tablet-xs) {
			min-height: 550px;
		}

		&__inner {
			@include above($tablet-xs) {
				min-height: 550px;
			}
		}
	}

	.section__spacing {
		&:nth-child(3) {
			padding-top: 0;
		}
	}
}
