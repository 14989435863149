.recipes__thumb {

	&-image {
		width: 100%;
		height: 240px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	&-title {
		padding: 0 15px;
		font-family: $title-font-family;
		font-weight: 600;
		font-size: 24px;
		line-height: 30px;
	}

	&-content {
		padding: 0 15px 15px 15px;
		font-size: 14px;

		p {
			line-height: 18px;
		}
	}

	&-list {
		padding: 0;
		margin: 0;

		text-transform: uppercase;

		li {
			list-style: none;
		}

		&.ingredients {
			text-transform: none;

			li {
				position: relative;
				padding-left: 15px;

				&:before {
					content: "-";
					position: absolute;
					left: 0;
				}
			}
		}
	}
}

a.recipes__thumb-inner {
	background-color: $green;
	color: $white;
	display: block;

	border-radius: 5px;
	padding: 5px;
	text-decoration: none;

	&:hover {
		color: $white;
		background-color: $light-green;
	}
}

.recipes__thumb-inner {
	background-color: $green;
	color: $white;
	display: block;

	border-radius: 5px;
	padding: 5px;
	text-decoration: none;
}
