.image__slider {

	.image__slide {
		min-height: 350px;
		position: relative;
		opacity: 1;

		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		transition: all .2s ease-in-out;

		@include above($tablet) {
			min-height: 500px;
		}

		&.has-link {
			&:hover {
				opacity: 0.6;
			}
		}

		a {
			position: absolute;
			left: 0;
			top: 0;

			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}

	&.slick-dotted.slick-slider {
		margin-bottom: 0;
	}

	.slick-dots {
		bottom: 35px;
		width: calc( 100% - 40px );

		li {
			margin: 0;

			button {
				&:before {
					font-size: rem(10px);
					color: $white;
					opacity: 1;
				}
			}

			&.slick-active {
				button {
					&:before {
						color: $dark-gray;
					}
				}
			}
		}
	}

	.slick-list,
	.slick-track,
	.slick-slide > div,
	.image__slide {
		height: 100%;
	}
}
