$col--1: calc( 100% / 12);

.flex__container {
  display: flex;
  flex-wrap: wrap;

  &.flex__center {
    justify-content: center;
  }

  &.hide {
    display: none;
	}

	&.flex__align--center {
		align-items: center;
	}

	&.flex__spacing {
		justify-content: space-between;
	}
}

.spacing__container {
	@include above($desktop-sm) {
		padding-left: $col--1;
	}
}

.col--3,
.col--3-2,
.col--4,
.col--6,
.col--12 {
  padding: rem(10px);


  @include above($desktop-sm) {
    padding: rem(20px);
  }
}

.col--1 {
	width: $col--1;
}

.col--3 {
  width: 100%;

  @include above($tablet-xs) {
    width: 50%;
  }

  @include above($tablet) {
    width: 33.33%;
  }
}

.col--4 {
  width: 100%;

  @include above($tablet-xs) {
    width: 50%;
  }

  @include above($tablet) {
    width: 25%;
  }
}

.col--3-2 {
  width: 100%;

  @include above($tablet-xs) {
    width: 50%;
  }

  @include above($tablet) {
    width: 66.66%;
  }
}

.col--6 {
  width: 100%;

  @include above($tablet) {
    width: 50%;
  }
}

.col--12 {
  width: 100%;
}

