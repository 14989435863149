// ==========================================================================
// Base
// Base rules are the defaults. They are almost exclusively single element
// selectors but it could include attribute selectors, pseudo-class selectors,
// child selectors or sibling selectors. Essentially, a base style says that
// wherever this element is on the page, it should look like this. ==========================================================================

* {
	outline: none;
}

.container {
	width: 100%;
	max-width: 1200px;

	margin: 0 auto;
	padding: 0 rem(20px);
}

h1,
.block__image--title {
	font-size: rem(34px);
	line-height: rem(38px);
	color: $white;

	font-family: $title-font-family;
	font-weight: 600;

	max-width: 900px;
	margin: 0 auto;

	@include above($tablet-xs) {
		font-size: rem(44px);
		line-height: rem(48px);
	}

	@include above($desktop-sm) {
		font-size: rem(64px);
		line-height: rem(68px);
	}
}

h2,
.section__title {
	font-size: 26px;
	line-height: 38px;

	font-family: $title-font-family;
	font-weight: 600;

	@include above($tablet-xs) {
		font-size: rem(36px);
		line-height: rem(48px);
	}
}

a:not(.btn) {

	color: $green;
	text-decoration: underline;

	transition: all .2s ease-in-out;

	&:hover {
		color: $light-green;
	}
}

.section__spacing {
	padding: 30px 0;

	@include above($tablet) {
		padding: 60px 0;
	}
}

main {
	@include below($desktop-sm) {
		padding-top: 62px;
	}

	margin: 50px 0;
}




.col--form,
.col--thankyou {
	&.hide {
		display: none;
	}
}