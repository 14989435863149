// ==========================================================================
// Fonts
// ==========================================================================
// Font-weights
// Thin (Hairline): 100
// Extra Light (Ultra Light): 200
// Light: 300
// Normal: 400
// Medium: 500
// Semi Bold (Demi Bold): 600
// Bold: 700
// Extra Bold (Ultra Bold): 800
// Black (Heavy): 900
// ==========================================================================

$font-path: if(variable-exists(font-path), $font-path, "../fonts/");
$font-display: if(variable-exists(font-display), $font-display, swap);

$fonts: (
  'Gotham': (
		(300, normal, "gotham/Gotham-Light"),
		(400, normal, "gotham/Gotham-Medium"),
		(600, normal, "gotham/Gotham-Bold"),
	),
	'Gotham Book': (
		(400, normal, "gotham/Gotham-Book"),
  )
);


@if(variable-exists(fonts)) {
  @each $name, $properties in $fonts {
    @each $weight, $style, $path in $properties {

      @font-face {
        font-family: $name;
        font-weight: $weight;
        font-style: $style;
        font-display: $font-display;
        src: url($font-path + $path + ".woff") format("woff"),
             url($font-path + $path + ".eot?#iefix") format('embedded-opentype'),
             url($font-path + $path + ".ttf") format('truetype'),
             url($font-path + $path + ".svg#webfont") format('svg');
      }

    }
  }
}
