// -------------------------------------------------------------------
// :: JS STYLING
// -------------------------------------------------------------------

.js-fixed-header {
	.header {

		&__logo {
			&:after {
				content: none;
			}
			img {
				@include above($desktop-sm) {
					width: rem(70px);
					height: auto;
				}
			}
		}

		.navigation__tel {
			display: none;
		}
	}
}

.header {
	width: 100%;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 10;

	@include below($desktop-sm) {
		background-color: $white;
		box-shadow: 0 2px 4px 0 rgba(193,193,193,0.50);
	}

	@include above($desktop-sm) {
		height: auto;
		position: absolute;

		&.fixed {
			position: fixed;
			background-color: $white;
			height: 90px;
			box-shadow: 0 2px 4px 0 rgba(193,193,193,0.50);

			.header__inner {
				align-items: center;
			}

			.header__nav {
				ul {
					li {
						a {
							color: $green;
						}
					}
				}
			}

			.navigation__language--item {
				&:after {
					background-color: $green;
				}
			}

			.navigation__list-item {
				a {
					&:after {
						background-color: $green;
					}
				}
			}
		}
  }

	&__logo {

		position: relative;

		@include above($desktop-sm) {

			&:after {
				content: "";
				background-color: $white;
				position: absolute;
				top: 0;
				left: -8px;

				width: 100%;
				height: 100%;

				border-bottom-left-radius: 15px;
				border-bottom-right-radius: 15px;
			}
		}

		img {
			width: 100px;
			height: auto;

			position: relative;
			z-index: 12;
			margin-top: -6px;
			padding-bottom: 6px;

			@include below($desktop-sm) {
				width: 50px;
				margin-top: 0;
				padding-bottom: 0;
			}
		}
	}

	&__nav {
		margin-top: 10px;
		margin-left: auto;

		@include below($desktop-sm) {
			vertical-align: middle;
			background-color: $white;

			position: fixed;
			z-index: 10;
			top: 0;
			left: -80%;

			height: 100%;
			min-height: 100%;
			margin-top: 0;
			padding-top: 80px;

			width: 80%;
			transition: all 250ms ease-in-out;

			overflow-y: auto;
			-webkit-overflow-scrolling: touch;
			box-shadow: 0 2px 4px 0 rgba(193,193,193,0.50);


			ul {
				li {
					a {
						color: $green;
					}
				}
			}

			.navigation__list {
				padding: 0 6px;
			}

			.navigation__list-item {
				display: block;
				padding: 10px 15px;
				position: relative;

				&.is-active {
					a {
						font-weight: 600;
					}
				}

				&:after {
					content: "";
					position: absolute;
					width: calc( 100% - 30px );
					height: 1px;
					left: 15px;
					bottom: 0;

					background-color: $gray;
				}
			}

			.navigation {
				display: block;
			}

			.navigation__language-wrapper {
				padding: 0 22px;
			}

			.navigation__language--item {
				&:after {
					background-color: $green;
				}
			}
		}

		&.open {
			display: block;
			left: 0;

			@include above($desktop-sm) {
				display: inline-block;
			}
		}

		.navigation,
		.navigation__language {
			display: inline-block;
		}

		ul {
			li {
				list-style: none;
				display: inline-block;
				padding: 0 15px;

				a {
					color: $white;
					font-family: $title-font-family;
					letter-spacing: 1px;
					text-decoration: none;
					text-transform: uppercase;

					&:hover {
						color: $white;
					}
				}
			}
		}



		.navigation__list-item {

			a {
				display: inline;
				position: relative;
				overflow: hidden;

				&:after {
					content: "";
					position: absolute;
					z-index: -1;
					right: 0;
					width: 0;
					bottom: -5px;

					background: $white;
					height: 1px;

					transition-property: width;
					transition-duration: 0.3s;
					transition-timing-function: ease-out;
				}

				&:hover,
				&:focus,
				&:active {
					&:after {
						left: 0;
						right: auto;
						width: 100%;
					}
				}
			}

			&.is-active {
				a {
					&:after {
						left: 0;
						right: auto;
						width: 100%;
					}
				}
			}
		}

		.navigation__language {
			ul {
				li {
					padding: 0;

					a {
						font-weight: 300;
						letter-spacing: 0;

					}
				}
			}

			&--item {
				position: relative;
				padding-right: 10px !important;
				font-size: 14px;

				&:after {
					content: "";
					position: absolute;
					width: 1px;
					height: 60%;
					background-color: $white;
					top: 5px;
					right: 2px;
				}

				&:last-child {
					&:after {
						content: none;
					}
				}

				a {
					&.active {
						font-weight: 400;
					}
				}
			}
		}
	}
}

// -------------------------------------------------------------------
// :: HAMBURGER MENU
// -------------------------------------------------------------------

.header {
	&__hamburger {
		width: rem(40px);
		height: rem(40px);

		position: relative;
		z-index: 10;

		transform: scale(0.8);
		transition: all 0.5s ease-in-out;

		float: right;
    vertical-align: middle;
		margin-top: 18px;
		margin-left: auto;

		cursor: pointer;

		@include above($desktop-sm) {
			display: none;
		}

		span {
			display: block;
			position: absolute;

			height: rem(4px);
			width: 100%;

			background-color: $green;
			border-radius: rem(10px);

			opacity: 1;
			left: 0;

			transform: rotate(0deg);
			transition: all 0.25s ease-in-out;
		}
	}

	&__bar {

		&:nth-child(1) {
			top: 0;
		}

		&:nth-child(2),
		&:nth-child(3) {
			top: rem(12px);
		}

		&:nth-child(4) {
			top: rem(24px);
		}
	}
}

// -------------------------------------------------------------------
// :: HAMBURGER MENU OPEN
// -------------------------------------------------------------------

.header {

	&__hamburger {

		&.open {

			.header__bar {

				&:nth-child(1),
				&:nth-child(4) {
					top: rem(18px);
					width: 0;
					left: 50%;
				}

				&:nth-child(2) {
					transform: rotate(45deg);
				}

				&:nth-child(3) {
					transform: rotate(-45deg);
				}

				&:nth-child(4) {
					top: rem(10px);
					width: 0;
					left: 50%;
				}
			}
		}
	}
}
