// ==========================================================================
// Variables
// ==========================================================================

$mobile: 480px;
$tablet-xs: 600px;
$tablet: 768px;
$desktop-sm: 990px;
$desktop: 1100px;
$desktop-md: 1280px;
$desktop-hd: 1440px;
$hd: 1650px;
$desktop-xxl: 2000px;

$base-margin: 20px;

// Colors
$white: #FFFFFF;
$cream: #FEFBF7;
$gray: #DDDDDD;
$mid-gray: #BBBBBB;
$dark-gray: #4A4A4A;
$black: #323241;

$light-blue: #86CEDC;
$blue: #00A7BA;

$light-green: #4DA330;
$green: #457B20;

$pink: #F19991;
$red: #DD042B;



// Paths
$base-path: '../';
$font-path: $base-path + 'fonts/';
$img-path:  $base-path + 'img/';


// Fonts
$base-font-family: 'Gotham Book', sans-serif;
$title-font-family: 'Gotham', sans-serif;

// Transitions
$base-transition: 'all .3s ease';

// Defaults
$root-box-sizing: border-box;
$font-smoothing: true;
$base-font-size: 16px;
$root-font-color: $black;
$font-display: swap;
