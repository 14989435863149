.page__recipe {
	.recipes__thumb {
		padding: 0;
	}

	ol {
		padding-left: 18px;
	}
}

.recipe__title {
	font-family: $title-font-family;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.recipe__content {
	margin-bottom: 60px;

	li {
		padding-bottom: 10px;
	}
}
