.recipes__thumbs {
	.recipes__thumb {
		display: none;
		> a {
			height: 100%;
		}
	}
}

.tooltip__box {
	background-color: $white;
	color: $green;
	visibility: hidden;
	opacity: 0;
	text-align: center;
	padding: 5px 0;
	border-radius: 3px;

	position: absolute;
	z-index: 1;

	width: 200px;
	top: 110%;
	left: 50%;
	margin-left: -100px;

	font-size: rem(14px);
	text-transform: none;
	box-shadow: 0 2px 4px 0 rgba(0,0,0,0.13);

	transition: all .2s ease-in-out;
}

.unlocked {
	cursor: pointer;
	transition: all .2s ease-in-out;

	&.active {
		font-weight: bold;
	}

	&:before {
		content: "";
		background-image: url($img-path + 'unlocked.svg');
		background-repeat: no-repeat;
		background-size: contain;

		width: 14px;
		height: 22px;

		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}

	&:hover {
		color: $light-green;
		&:before {
			animation: unlocked 0.82s cubic-bezier(.36,.07,.19,.97) both;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;
		}
	}
}

.locked {
	position: relative;

	&:before {
		content: "";
		background-image: url($img-path + 'locked.svg');
		background-repeat: no-repeat;
		background-size: contain;

		width: 14px;
		height: 22px;

		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}

	&:hover {
		&:before {
			animation: locked 0.82s cubic-bezier(.36,.07,.19,.97) both;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;
		}

		.tooltip__box {
			visibility: visible;
			opacity: 1;
		}
	}

}

@keyframes unlocked {
	0% {
		transform: scale(1) rotate(0deg);
	}
	33% {
		transform: scale(1.2) rotate(5deg);
	}
	66% {
		transform: scale(1.2) rotate(-5deg);
	}
	100% {
		transform: scale(1) rotate(0deg);
	}
}

@keyframes locked {
	0% {
		transform: rotate(0deg);
		transform-origin: center top;
	}
	10% {
		transform: rotate(1deg);
		transform-origin: center top;
	}
	20% {
		transform: rotate(-2deg);
		transform-origin: center top;
	}
	30% {
		transform: rotate(3deg);
		transform-origin: center top;
	}
	40% {
		transform: rotate(-4deg);
		transform-origin: center top;
	}
	50% {
		transform: rotate(4deg);
		transform-origin: center top;
	}
	60% {
		transform: rotate(-4deg);
		transform-origin: center top;
	}
	70% {
		transform: rotate(3deg);
		transform-origin: center top;
	}
	80% {
		transform: rotate(-2deg);
		transform-origin: center top;
	}
	90% {
		transform: rotate(1deg);
		transform-origin: center top;
	}
	100% {
		transform: rotate(0);
		transform-origin: center top;
	}
}
