.intro__block {

	&--inner {
		@include above($tablet) {
			max-width: 280px;
		}
	}

	h3 {
		color: $green;
		letter-spacing: 1.5px;
		line-height: 22px;
		text-transform: uppercase;
		font-weight: 300;
	}

	&--description {
		font-size: rem(14px);
	}
}
